import React, { FC } from "react";
import { Row, Col, Typography } from "antd";

import { FooterProps } from "./Footer.prop";
import styles from "./Footer.module.css";
import { useTranslate } from "../../i18n";

const { Paragraph, Link } = Typography;

const Footer: FC<FooterProps> = (props) => {
  const translate = useTranslate();
  return (
    <Row {...props} className={styles.footer}>
      <Col span={24}>
        <Row justify="center">
          <Paragraph>{translate("footer.profit")}</Paragraph>
        </Row>
        <Row justify="center">
          <Link
            href="http://online.e-health.kz/Политика конфиденциальности.docx"
            target="_blank"
          >
            {translate("footer.privacyPolicy")}
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
