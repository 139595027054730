import { AppLocales } from "../../i18n";

export const getLocale = (locale: AppLocales) => ({
  lang: {
    locale: locale,
    placeholder: "Выбор даты",
    today: "Сегодня",
    now: "Сейчас",
    backToToday: "Вернуться",
    ok: "OK",
    clear: "Очистить",
    month: "Месяц",
    year: "Год",
    timeSelect: "Выберите время",
    dateSelect: "Выберите дату",
    monthSelect: "Выберите месяц",
    yearSelect: "Выберите год",
    decadeSelect: "Выберите декаду",
    yearFormat: "YYYY",
    dateFormat: "M/D/YYYY",
    dayFormat: "D",
    dateTimeFormat: "M/D/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Предыдущий месяц (PageUp)",
    nextMonth: "Следующий месяц (PageDown)",
    previousYear: "Прошлый год (Control + left)",
    nextYear: "Следующий год (Control + right)",
    previousDecade: "Последняя декада",
    nextDecade: "Следующая декада",
    previousCentury: "Прошлый век",
    nextCentury: "Следующий век",
  },
  timePickerLocale: {},
});
