import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Alert,
  Grid,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { FirstFormProps } from "./FirstForm.props";
import { getAppointmentErrorMessageState } from "../../store/selectors/appointment";
import {
  getHospitalsErrorState,
  getHospitalsForAppointmentState,
} from "../../store/selectors/hospitals";
import { getIsTerminal } from "../../store/selectors/app";
import { useTranslate } from "../../i18n";

const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

const checkIIN = (IIN: string) => {
  const regex = /^(\d{12})$/;
  return regex.test(IIN);
};

const keyboardLayout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
};

export const FirstForm: FC<FirstFormProps> = ({
  submitForm,
  setCaptchaResp,
  setIIN,
  setHospitalId,
  hospitalId,
  IIN,
  captchaResp,
  clearError,
}) => {
  const [hospitalWarning, setHospitalWarning] = useState<null | string>(null);
  const [isKeyboardVisisble, setIsKeyboardVisible] = useState(false);

  const appointmentError = useSelector(getAppointmentErrorMessageState);
  const hospitalsForAppointment = useSelector(getHospitalsForAppointmentState);
  const hospitalError = useSelector(getHospitalsErrorState);

  const isTerminal = useSelector(getIsTerminal);

  const { sm } = useBreakpoint();
  const translate = useTranslate();

  const showKeyboard = () => {
    setIsKeyboardVisible(true);
  };

  const hideKeyboard = () => {
    setIsKeyboardVisible(false);
  };

  const handleKeyboadrPress = (btn: string) => {
    if (btn === "{enter}" && !isSubmitBtnDisabled()) {
      submitForm();
    }
  };

  useEffect(() => {
    const closeKeyboard = (e: MouseEvent) => {
      const target = e.target as HTMLDivElement;

      if (
        !target.closest(".react-simple-keyboard") &&
        !target.closest(".input--iin")
      ) {
        hideKeyboard();
      }
    };
    if (isKeyboardVisisble) {
      document.documentElement.addEventListener("click", closeKeyboard);
    }
    return () => {
      document.documentElement.removeEventListener("click", closeKeyboard);
    };
  }, [isKeyboardVisisble]);

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaResp(token);
  };

  const handleChangeIIN = (e: ChangeEvent<HTMLInputElement>) => {
    setIIN(e.target.value);
  };

  const handleChangeHospital = (hospitalId: string) => {
    if (setHospitalWarning) {
      setHospitalWarning(null);
    }

    const selectedHospitalInfo = hospitalsForAppointment?.find(
      (orgInfo) => orgInfo.OrgID === hospitalId
    );

    if (selectedHospitalInfo?.ShowMessage && selectedHospitalInfo.MessageText) {
      setHospitalWarning(selectedHospitalInfo.MessageText);
    }

    setHospitalId(hospitalId);
  };

  const isSubmitBtnDisabled = () => {
    if (isTerminal) {
      return !checkIIN(IIN) || !Boolean(hospitalId);
    }
    return !Boolean(captchaResp) || !checkIIN(IIN) || !Boolean(hospitalId);
  };

  if (appointmentError || hospitalError) {
    return (
      <Modal
        title={translate("common.error")}
        onCancel={clearError}
        onOk={clearError}
        cancelText={translate("common.close")}
        visible
        centered
      >
        {appointmentError && <p>{appointmentError}</p>}
        {hospitalError && <p>{hospitalError}</p>}
      </Modal>
    );
  }

  return (
    <>
      {hospitalWarning && (
        <Col>
          <Row justify="center">
            <Col sm={16}>
              <Alert
                description={hospitalWarning}
                type="warning"
                showIcon
                closable
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col className="form">
        <Row justify="center">
          <Title className="title">{translate("header.title")}</Title>
        </Row>
        <Row justify="center">
          <Col sm={14} className="input_wrapper">
            <Text className="subtitle">
              {translate("selectors.organization")}
            </Text>
            <Select
              defaultValue={"0"}
              size={sm ? "large" : "middle"}
              className="select"
              onChange={handleChangeHospital}
              value={hospitalId}
              disabled
            >
              {hospitalsForAppointment &&
                hospitalsForAppointment.map((org) => (
                  <Option key={org.OrgID} value={org.OrgID}>
                    {org.Name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col sm={14} className="input_wrapper">
            <Text className="subtitle">{translate("inputs.iin")}</Text>
            <Input
              placeholder={translate("inputs.iin")}
              size={sm ? "large" : "middle"}
              className="input input--iin"
              maxLength={12}
              onChange={handleChangeIIN}
              value={IIN}
              onFocus={showKeyboard}
              autoFocus
            />
          </Col>
        </Row>
        {!isTerminal && (
          <Row justify="center" className="captcha">
            <ReCAPTCHA
              sitekey="6LfDIvIbAAAAADAA-OmE0fOpkthXu2BYqzgHjqFI"
              onChange={handleCaptchaChange}
            />
          </Row>
        )}
        <Row justify="center">
          <Button
            type="primary"
            size={sm ? "large" : "middle"}
            disabled={isSubmitBtnDisabled()}
            onClick={submitForm}
          >
            {translate("common.continue")}
          </Button>
        </Row>
      </Col>
      {isKeyboardVisisble && isTerminal && (
        <Row>
          <Keyboard
            layout={keyboardLayout}
            onChange={setIIN}
            display={{
              "{bksp}": translate("common.delete"),
              "{enter}": translate("common.next"),
            }}
            onKeyPress={handleKeyboadrPress}
          />
        </Row>
      )}
    </>
  );
};
