import { Button, Col, Modal, Row, Segmented, Typography, Grid } from "antd";
import React, { FC, useEffect } from "react";
import { SegmentedValue } from "antd/lib/segmented";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { SelectScheduleFormProps } from "./SelectScheduleForm.props";
import styles from "./SelectScheduleForm.module.css";
import { RecordMethodType } from "../../models/Appointment";
import { getDoctors, getNGTypePay, getSpecialities } from "../../store/actions/appointment";
import {
  getDoctorsLoadingState,
  getDoctorsState,
  getSpecialitiesState,
  getSpecialitiesLoadingState,
  getAppointmentErrorMessageState,
  getAppointmentUserDataState,
  getTypePayState,
} from "../../store/selectors/appointment";
import { DoctorSelect } from "../DoctorSelect/DoctorSelect";
import { SpecialitySelect } from "../SpecialitySelect/SpecialitySelect";
import { useTranslate } from "../../i18n";
import { TypePaySelect } from "../TypePay/TypePay";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const SelectScheduleForm: FC<SelectScheduleFormProps> = ({
  hospitalId,
  submitForm,
  goBack,
  clearError,
  selectedDoctor,
  selectedTypePay,
  setDoctor,
  setNGTypePay,
  selectedSpeciality,
  setSpecialities,
  recordType,
  setRecordType,
}) => {
  const doctors = useSelector(getDoctorsState);
  const doctorsLoading = useSelector(getDoctorsLoadingState);

  const typePay = useSelector(getTypePayState);

  const specialities = useSelector(getSpecialitiesState);
  const specialitiesLoading = useSelector(getSpecialitiesLoadingState);

  const appointmentError = useSelector(getAppointmentErrorMessageState);

  const appointmentUserData = useSelector(getAppointmentUserDataState);

  const dispatch = useDispatch();
  const { sm } = useBreakpoint();
  const translate = useTranslate()

  useEffect(() => {
    if (typePay) {
      setNGTypePay(typePay[0]);
      selectedTypePay = typePay[0];
    }
  }, [typePay, setNGTypePay]);
  
  useEffect(() => {
    if (doctors) {
      setDoctor(doctors[0]);
    }
  }, [doctors, setDoctor]);

  useEffect(() => {
    if (specialities) {
      setSpecialities(specialities[0]);
    }
  }, [setSpecialities, specialities]);

  useEffect(() => {
    const orgId =
      hospitalId === "0" ? appointmentUserData?.AttachmentID : hospitalId;
    if (orgId) {
      if (recordType === translate("recordMethods.fio") && !doctors) {
        if (hospitalId === "1101") {
          dispatch(getNGTypePay());
          dispatch(getDoctors(orgId, null, selectedTypePay?.key));
        } else {
          dispatch(getDoctors(orgId));
        }
      } else if (recordType === translate("recordMethods.specialization") && !specialities) {
        if (hospitalId === "1101") {
          dispatch(getNGTypePay());
          dispatch(getSpecialities(orgId, null, selectedTypePay?.key));
        } else {
          dispatch(getSpecialities(orgId));
        }

      }
    }
  }, [
    dispatch,
    recordType,
    hospitalId,
    doctors,
    specialities,
    appointmentUserData?.AttachmentID,
  ]);

  const handleChangeRecordMethod = (value: SegmentedValue) => {
    setRecordType(value as RecordMethodType);
  };

  const handleChangeDoctor = (doctorId: string) => {
    const selectedDoctor = doctors?.find((doc) => doc.doctor_id === doctorId);
    if (selectedDoctor) {
      setDoctor(selectedDoctor);
    }
  };

  const handleChangeTypePay = (TypePayId: string) => {
    const selectedTypePay = typePay?.find((doc) => doc.key === TypePayId);
    if (selectedTypePay) {
      setNGTypePay(selectedTypePay);
    }
    if (hospitalId != null) {
      if (recordType === translate("recordMethods.fio")) {
        dispatch(getDoctors(hospitalId, null, selectedTypePay?.key));
      } else if (recordType === translate("recordMethods.specialization")) {
        dispatch(getSpecialities(hospitalId, null, selectedTypePay?.key));
      }
    }
  };

  const handleChangeSpeciality = (specialityId: string) => {
    const selectedSpeciality = specialities?.find(
      (spec) => spec.doc_speciality_id === specialityId
    );
    if (selectedSpeciality) {
      setSpecialities(selectedSpeciality);
    }
  };

  if (appointmentError) {
    return (
      <Modal
        title={translate("common.error")}
        onCancel={clearError}
        onOk={clearError}
        cancelText={translate("common.close")}
        visible
        centered
      >
        <p>{appointmentError}</p>
      </Modal>
    );
  }

  return (
    <>
      <Col className={cn("form", styles.form)}>
        <Row justify="center">
          <Col className="input_wrapper">
            <Text className="subtitle">{translate("recordMethods.title")}</Text>
            <Segmented
              options={[translate('recordMethods.fio'), translate('recordMethods.specialization')]}
              onChange={handleChangeRecordMethod}
              value={recordType}
              disabled={doctorsLoading || specialitiesLoading}
              size={sm ? "large" : "middle"}
              block
            />
          </Col>
          {hospitalId === "1101" &&
            <Col className="input_wrapper">
              <TypePaySelect
                selectedTypePay={selectedTypePay?.key}
                onChange={handleChangeTypePay}
                TypePay={typePay}
                size={sm ? "large" : "middle"}
              />
            </Col>}
          {recordType === translate("recordMethods.fio") ? (
            <DoctorSelect
              selectedDoctorId={selectedDoctor?.doctor_id}
              onChange={handleChangeDoctor}
              doctors={doctors}
              isLoading={doctorsLoading}
              size={sm ? "large" : "middle"}
            />
          ) : (
            <SpecialitySelect
              specialities={specialities}
              onChange={handleChangeSpeciality}
              selectedSpecialityId={selectedSpeciality?.doc_speciality_id}
              isLoading={specialitiesLoading}
              size={sm ? "large" : "middle"}
            />
          )}
        </Row >
        <Row justify="space-between" className={styles.footer}>
          <Button
            type="default"
            size={sm ? "large" : "middle"}
            onClick={goBack}
          >
            {translate("common.back")}
          </Button>
          <Button
            type="primary"
            size={sm ? "large" : "middle"}
            onClick={submitForm}
            disabled={
              recordType === translate("recordMethods.fio") ? !selectedDoctor : !selectedSpeciality
            }
          >
            {translate('common.continue')}
          </Button>
        </Row>
      </Col >
    </>
  );
};
