import { AppLocales } from "../../i18n";
import { Terminal } from "../../models/App";
import terminals from "../../terminals";
import { AppActionsType } from "../actions/app";

const initialState = {
  appInit: false,
  isTerminal: false,
  currentTerminal: null as null | undefined | Terminal,
  terminalLang: null as null | string,
  terminalPoint: null as null | string,
  terminalHostId: null as null | string,
  locale: "kz" as AppLocales,
};

type initStateType = typeof initialState;

export const appReducer = (
  state = initialState,
  action: AppActionsType
): initStateType => {
  switch (action.type) {
    case "SET_APP_INIT": {
      return {
        ...state,
        appInit: action.payload,
      };
    }

    case "SET_CURRENT_TERMINAL": {
      return {
        ...state,
        currentTerminal: terminals[action.payload],
      };
    }

    case "SET_IS_TERMINAL": {
      return {
        ...state,
        isTerminal: action.payload,
      };
    }

    case "SET_TERMMINAL_LANG": {
      return {
        ...state,
        terminalLang: action.payload,
      };
    }

    case "SET_TERMINAL_POINT": {
      return {
        ...state,
        terminalPoint: action.payload,
      };
    }

    case "SET_TERMINAL_HOST_ID": {
      return {
        ...state,
        terminalHostId: action.payload,
      };
    }

    case "SET_LOCALE": {
      return {
        ...state,
        locale: action.payload,
      };
    }

    default:
      return state;
  }
};
