import { AppStateType } from "./../store";
export const getAppState = (state: AppStateType) => state.app;

export const getAppInitState = (state: AppStateType) =>
  getAppState(state).appInit;

export const getCurrentTerminal = (state: AppStateType) =>
  getAppState(state).currentTerminal;
export const getIsTerminal = (state: AppStateType) =>
  getAppState(state).isTerminal;
export const getTerminalLang = (state: AppStateType) =>
  getAppState(state).terminalLang;
export const getTerminalPoint = (state: AppStateType) =>
  getAppState(state).terminalPoint;
export const getTerminalHostId = (state: AppStateType) =>
  getAppState(state).terminalHostId;
export const getLocaleState = (state: AppStateType) =>
  getAppState(state).locale;
