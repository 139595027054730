import { ThunkAction } from "redux-thunk";
import i18n from "i18next";

import { AppLocales } from "../../i18n";
import { ActionsCreatorsTypes, Terminal } from "../../models/App";
import { AppStateType } from "../store";

export const appActions = {
  setAppInit: (payload: boolean) => {
    return {
      type: "SET_APP_INIT",
      payload,
    } as const;
  },
  setCurrentTerminal: (payload: string) => {
    return {
      type: "SET_CURRENT_TERMINAL",
      payload,
    } as const;
  },
  setIsTerminal: (payload: boolean) => {
    return {
      type: "SET_IS_TERMINAL",
      payload,
    } as const;
  },
  setTerminalLang: (payload: null | string) => {
    return {
      type: "SET_TERMMINAL_LANG",
      payload,
    } as const;
  },
  setTerminalPoint: (payload: null | string) => {
    return {
      type: "SET_TERMINAL_POINT",
      payload,
    } as const;
  },
  setTerminalHostId: (payload: null | string) => {
    return {
      type: "SET_TERMINAL_HOST_ID",
      payload,
    } as const;
  },
  setLocale: (payload: AppLocales) => {
    return {
      type: "SET_LOCALE",
      payload,
    } as const;
  },
};

export const changeLocale =
  (locale: AppLocales): ThunkAcionType =>
  async (dispatch) => {
    dispatch(appActions.setLocale(locale));
    localStorage.setItem("locale", locale);
    i18n.changeLanguage(locale);
  };

export type AppActionsType = ReturnType<
  ActionsCreatorsTypes<typeof appActions>
>;

type ThunkAcionType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  AppActionsType
>;
