import React, { FC } from "react";
import { Row, Col, Grid, Button } from "antd";
import cn from "classnames";
import { Link } from "react-router-dom";

import { HeaderLinkProps, HeaderProps } from "./Header.prop";
import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppInitState,
  getCurrentTerminal,
  getIsTerminal,
  getLocaleState,
  getTerminalHostId,
  getTerminalLang,
  getTerminalPoint,
} from "../../store/selectors/app";
import { Terminal } from "../../models/App";
import { changeLocale } from "../../store/actions/app";
import { useTranslate } from "../../i18n";

const { useBreakpoint } = Grid;

const HeaderLink: FC<HeaderLinkProps> = ({
  to,
  native = false,
  title,
  ...props
}) => {
  const { sm, md } = useBreakpoint();

  if (native) {
    return (
      <a href={to} {...props}>
        <Button size={sm ? "large" : "middle"} block={!md} role="link">
          {title}
        </Button>
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      <Button size={sm ? "large" : "middle"} block={!md} role="link">
        {title}
      </Button>
    </Link>
  );
};

const Header: FC<HeaderProps> = (props) => {
  const { sm } = useBreakpoint();
  const isTerminal = useSelector(getIsTerminal);
  const currentTerminal = useSelector(getCurrentTerminal);
  const terminalLang = useSelector(getTerminalLang);
  const terminalPoint = useSelector(getTerminalPoint);
  const terminalHostId = useSelector(getTerminalHostId);
  const locale = useSelector(getLocaleState);

  const appInit = useSelector(getAppInitState);

  const dispatch = useDispatch();
  const translate = useTranslate();

  const getBackUrl = (currentTerminal: Terminal) => {
    const lang = terminalLang ? `?lang=${terminalLang}` : "?lang=1";
    const point = terminalPoint ? `&point=${terminalPoint}` : "";
    const hostID = terminalHostId ? `&hostID=${terminalHostId}` : "";
    return `${currentTerminal.backUrl}${lang}${point}${hostID}`;
  };

  return (
    <Row {...props} className={styles.header}>
      <Col span={24}>
        <Row justify="space-between">
          {isTerminal && currentTerminal && (
            <Col>
              <HeaderLink
                to={getBackUrl(currentTerminal)}
                title={translate("header.back")}
                type="secondary"
                native
              />
            </Col>
          )}
          <Col flex={1}>
            {appInit && (
              <Row
                className={cn(styles.buttons, styles.buttons__langs)}
                justify="end"
              >
                <Button
                  type={locale === "kz" ? "primary" : "default"}
                  size={sm ? "large" : "middle"}
                  onClick={() => dispatch(changeLocale("kz"))}
                >
                  KZ
                </Button>
                <Button
                  type={locale === "ru" ? "primary" : "default"}
                  size={sm ? "large" : "middle"}
                  onClick={() => dispatch(changeLocale("ru"))}
                >
                  RU
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
