const ru = {
  common: {
    error: "Ошибка",
    close: "Закрыть",
    continue: "Продолжить",
    delete: "Удалить",
    next: "Далее",
    back: "Назад",
  },
  header: {
    back: "Назад в меню",
    title: "Запись на приём online",
  },
  userData: {
    title: "Информация о пациенте",
    fio: "ФИО",
    familyDoctor: "Участковый врач",
    territory: "Участок",
    status: "Статус ОСМС",
    polyclinic: "Поликлиника",
  },
  footer: {
    profit:
      "Медицинская информационная система «НАДЕЖДА», разработчик ИП«PROFit»",
    privacyPolicy: "Политика конфиденциальности",
  },
  selectors: {
    doctor: "Выберите врача",
    typePay: "Выберите тип оплаты",
    organization: "Выберите организацию",
    date: "Выберите дату",
    time: "Выберите время",
    schedule: "Выберите расписание",
    specialization: "Выберите специализацию",
  },
  inputs: {
    iin: "Введите ИИН",
  },
  errors: {
    common: "Ошибка сети! Не удалось загрузить данные!",
    userData: "Ошибка при получении данных о пользователе!",
    branches: "Ошибка сети! Не удалось получить данные о подразделениях!",
    specialities: "Ошибка сети! Не удалось получить данных по специализациям!",
    doctorsList: "Ошибка сети! Не удалось получить список врачей!",
    doctorSchedule:
      "Ошибка сети! Не удалось получить данные об расписании врача!",
    schedules: "Ошибка сети! Не удалось получить данные по расписаниям врачей",
    appointment: "Ошибка сети! Не удалось записаться на прием!",
    profileNotAvailable: "Запись к узким специалистам недоступна",
    medOrgNotAvailable:
      "Запись в мед.организацию прикрепления пациента недоступна",
    organizations: "Ошибка при загрузки списка организаций",
  },
  recordTypes: {
    profile: "К узким специалистам",
    family: "К участковому врачу",
  },
  recordMethods: {
    title: "Способ записи",
    fio: "По ФИО",
    specialization: "По специализации",
  },
  secondForm: {
    title: "Куда хотите записаться?",
  },
  steps: {
    1: "Заполните форму",
    2: "Подтвердите информацию",
    3: "Выберите способ записи",
    4: "Выберите время приема",
  },
  saveAppointmentResult: {
    title: "Вы успешно записали на прием {{patientName}}",
    subTitle: "Прием начнется {{date}} в {{time}}",
  },
};
export default ru;
export type Translations = typeof ru;
