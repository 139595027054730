import { Typography } from "antd";
import React, { FC } from "react";
import { useTranslate, TxKeyPath } from "../../i18n";
import { UserDataType } from "../../models/User";

import styles from "./UserDataBox.module.css";
import { UserDataBoxProps } from "./UserDataBox.props";

const { Text } = Typography;

const items = [
  {
    title: "userData.fio",
    key: "FIO" as keyof UserDataType,
  } as const,
  {
    title: "userData.familyDoctor",
    key: "Doctor" as keyof UserDataType,
  } as const,
  {
    title: "userData.territory",
    key: "Territory" as keyof UserDataType,
  } as const,
  {
    title: "userData.status",
    key: "StatusOSMS" as keyof UserDataType,
  } as const,
  {
    title: "userData.polyclinic",
    key: "Attachment" as keyof UserDataType,
  } as const,
] as const;

const UserDataBox: FC<UserDataBoxProps> = ({
  appointmentUserData,
  ...props
}) => {

  const translate = useTranslate()



  return (
    <>
      <Text className={styles.title}>{translate("userData.title")}</Text>
      <div className={styles.info} {...props}>
        {items.map((item) => (
          <div className={styles.infoItem} key={item.key}>
            <div className={styles.infoLabel}>{translate(item.title)}</div>
            <div className={styles.infoValue}>
              {appointmentUserData[item.key]}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserDataBox;
