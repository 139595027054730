import { Translations } from "./ru";

const kz: Translations = {
  common: {
    error: "Қате",
    close: "Шығу",
    continue: "Жалғастыру",
    delete: "Жою",
    next: "Әрі қарай",
    back: "Артқа",
  },
  header: {
    back: "Артқа",
    title: "Дәрігердің қабылдауына жазылу",
  },
  userData: {
    title: "Пациент туралы ақпарат",
    fio: "Аты-жөні",
    familyDoctor: "Учаскелік дәрігер",
    territory: "Учаске",
    status: "Мәртебе",
    polyclinic: "Емхана",
  },
  footer: {
    profit: "«НАДЕЖДА» медициналық ақпараттық жүйесі",
    privacyPolicy: "Құпиялылық саясаты",
  },
  selectors: {
    doctor: "Дәрігерді таңдаңыз",
    typePay: "Төлем түрін таңдаңыз",
    organization: "Ұйымды таңдаңыз",
    date: "Күнді таңдаңыз",
    time: "Уақытты таңдаңыз",
    schedule: "Жұмыс кестені таңдаңыз",
    specialization: "Мамандануды таңдаңыз",
  },
  inputs: {
    iin: "ЖСН таңдаңыз",
  },
  errors: {
    common: "Желдегі  қате!  Деректерді жүктеу мүмкін болмады!",
    userData: "Пайдаланушы деректерін алу кезінде қате!",
    branches:
      "Желдегі  қате!  Бөлімшелер туралы деректерді алу мүмкін болмады!",
    specialities:
      "Желдегі  қате!  Мамандықтар бойынша деректерді алу мүмкін болмады!",
    doctorsList: "Желдегі  қате!  Дәрігерлер тізімін алу мүмкін болмады!",
    doctorSchedule:
      "Желдегі  қате! Дәрігердің кестесі туралы деректерді алу мүмкін болмады!",
    schedules:
      "Желдегі  қате! Дәрігерлердің  жұмыс кестесі бойынша деректерді алу мүмкін болмады",
    appointment: "Желдегі  қате!  Қабылдауға жазылу мүмкін болмады!",
    profileNotAvailable: "Тар бейінді мамандарға жазылу",
    medOrgNotAvailable:
      "Медициналық ұйымға жазылу пациентті тіркеу  қол жетімді емес",
    organizations: "Ұйымдар тізімін жүктеу кезіндегі қате",
  },
  recordTypes: {
    profile: "Тар бейінді мамандарға",
    family: "Учаскелік дәрігерге",
  },
  recordMethods: {
    title: "Жазылу әдісі",
    fio: "Аты-жөні бойынша",
    specialization: "Мамандану бойынша",
  },
  secondForm: {
    title: "Қайда жазылғыңыз келеді?",
  },
  steps: {
    1: "Нысанды толтырыңыз",
    2: "Ақпаратты растаңыз",
    3: "Жазылу әдісін таңдаңыз",
    4: "Қабылдау уақытын таңдаңыз",
  },
  saveAppointmentResult: {
    title: "Сіз  қабылдауға сәтті жазылдыңыз {{patientName}}",
    subTitle: "Қабылдаудың  басталу {{date}} {{time}}",
  },
};
export default kz;
