import { Terminal } from "./models/App";

const terminals: {
  [id: string]: Terminal;
} = {
  "1": {
    // 3 гп взрослый корпус 2 терминала
    backUrl: "http://192.168.1.2:8081/qsystem.html",
    accessProfileDoctors: false,
  },
  "2": {
    //3гп большой терминал
    backUrl: "http://clinic.e-health.kz/terminal",
    accessProfileDoctors: false,
  },
};

export default terminals;
