import React, { FC } from "react";
import { Col, Select, Typography } from "antd";

import { TypePaySelectProps } from "./TypePay.props";
import { useTranslate } from "../../i18n";

const { Text } = Typography;
const { Option } = Select;

export const TypePaySelect: FC<TypePaySelectProps> = ({
  TypePay,
  selectedTypePay,
  onChange,
  size = "large",
}) => {
  const translate = useTranslate();
  return (
    <Col>
      <Text className="subtitle">{translate("selectors.typePay")}</Text>
      <Select
        size={size}
        className="select"
        onChange={onChange}
        value={selectedTypePay}
        placeholder={translate("selectors.typePay")}
      >
        {TypePay &&
          TypePay.map((doc) => (
            <Option key={doc.key} value={doc.key}>
              {doc.name}
            </Option>
          ))}
      </Select>
    </Col>
  );
};
