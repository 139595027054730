import React, { FC, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import styles from "./Layout.module.css";
import { LayoutProps } from "./Layout.prop";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { appActions } from "../store/actions/app";

const Layout: FC<LayoutProps> = ({ children, ...props }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const terminalId = searchParams.get("term");
    const lang = searchParams.get("lang");
    const point = searchParams.get("point");
    const hostId = searchParams.get("hostID");

    if (lang) {
      dispatch(appActions.setTerminalLang(lang));
    }

    if (point) {
      dispatch(appActions.setTerminalPoint(point));
    }

    if (hostId) {
      dispatch(appActions.setTerminalHostId(hostId));
    }

    if (terminalId) {
      dispatch(appActions.setCurrentTerminal(terminalId));
      dispatch(appActions.setIsTerminal(true));
    }
  }, [searchParams]);

  return (
    <Col span={24} {...props}>
      <Row justify="center">
        <Col xxl={14} xl={20} xs={24} className={styles.content}>
          <Header />
          <main className={styles.main}>{children}</main>
          <Footer />
        </Col>
      </Row>
    </Col>
  );
};

export default Layout;
