import axios from "axios";

export default axios.create({
  baseURL: "https://skomedapp.e-health.kz:3032/",
  timeout: 10000,
  headers: {
    Authorization: "Basic YWRtaW46cGNaNzY5WD0tLUxTSlgmK1c3",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
  },
});
